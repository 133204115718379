/* eslint-disable */
/* global document, Office */

import { resources } from "../resources.js";

document.getElementById("sendMessage").querySelector(".ms-Button-label").innerText = resources.continueButton;
document.getElementById("loadingMessages").innerText = resources.creatingMessagesFromFolderIndicatorName;

var SpinnerElements = document.querySelectorAll(".ms-Spinner");
for (var i = 0; i < SpinnerElements.length; i++) {
    new fabric['Spinner'](SpinnerElements[i]);
}
const loadingAccount = document.querySelector(".docs-DialogLoadingAccount-blocking");
const dialog = loadingAccount.querySelector(".ms-Dialog");
const dialogComponent = new fabric['Dialog'](dialog);
dialogComponent.open();

Office.onReady(() => {

    Office.context.ui.messageParent(JSON.stringify({ message: "Ready" }));

    Office.context.ui.addHandlerAsync(Office.EventType.DialogParentMessageReceived, (response) => {

        const messagesFromParent = JSON.parse(response.message);
        var TableElements = document.querySelectorAll(".ms-Table");

        for (let i = 0; i < TableElements.length; i++) {
            new fabric['Table'](TableElements[i]);
        }

        let num = 0;
        let rows = "";

        rows = messagesFromParent.map((message) => {
            num++;
            let str = `<tr class="is-selected"><td class="ms-Table-rowCheck"></td><td>${num} 
            </td><td id="name"> ${message.name}
            </td><td> ${message.subject}
            </td><td> ${message.from}
            </td><td> ${message.to} 
            </td></tr>`;
            return str;
        })
        $('.ms-Table > tbody').append(rows);
        dialogComponent.close();
        
    });

    document.getElementById("sendMessage").addEventListener("click", () => {
        let messages = [];
        for (let row of document.querySelector(".ms-Table tbody").rows) {
            if (row.classList.contains("is-selected")) {
                let messageToSend = {
                    id: row.cells[1].innerText,
                    name: row.cells[2].innerText
                }

                messages.push(messageToSend)
            }
        }
        Office.context.ui.messageParent(JSON.stringify({ messages }));
        
    })


    let rows = document.querySelector(".ms-Table").rows;
    let firstRow = rows[0];
    firstRow.addEventListener("click", () => {
        if (!firstRow.classList.contains("is-selected")) {
            for (let i = 1; i < rows.length; i++) {
                rows[i].classList.add("is-selected");
            }
        }
        else {
            for (let i = 1; i < rows.length; i++) {
                rows[i].classList.remove("is-selected");
            }
        }
    })

})
